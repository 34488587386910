<template>
  <div class="app-container">
      <div class="jumbotron p-2 pt-3 text-center mb-0"  >
        <h5> <i class="pi pi-chart-bar" style="fontSize: 1.5rem"></i> Stitch it Reports</h5>
        <h6 class="font-weight-bold">Get the complete reporting of store.</h6>
      </div>
    <section class="section-menu">
      <div class="col-md-12">
        <div class="row">
          <template v-for="item in reportList" :key="item">
            <router-link
              :to="item.to"
              class="col-12 col-md-3 text-center hover-settings"
            >
              <img class="menu-img-settings" :src="item.image" />
              <h5 class="menu-link-settings">{{ item.label }}</h5>
              <p class="menu-link-detail-settings">
                {{ item.description }}
              </p>
            </router-link>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
export default class Initialization extends Vue {
  private home = { icon: "pi pi-home", to: "/" };
  private items = [{ label: "Report" }];
  private reportList = [
    {
      label: "Associate Report",
      image: require("@/assets/menu-icons/employee.png"),
      to: "/store/associate-report",
      description:"Here goes description there Here goes description thereHere goes description there",
    },
    {
      label: "Time Schedule Report",
      image: require("@/assets/menu-icons/time_report.png"),
      to: "/store/time-sheet-report",
      description:
        "Here goes description there Here goes description thereHere goes description there",
    },
    {
      label: "Flash Report",
      image: require("@/assets/menu-icons/flash_report.png"),
      to: "/store/flash-report",
      description:
        "Here goes description there Here goes description thereHere goes description there",
    },
    {
      label: "Dry Cleaners Invoice",
      image: require("@/assets/menu-icons/dryclean.png"),
      to: "/store/dry-clean-invoice-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Coupon Report",
      image: require("@/assets/menu-icons/discount.png"),
      to: "/store/coupon-report",
      description:
        "Here goes description there Here goes description thereHere goes description there",
    },
    {
      label: "Product Sales Report",
      image: require("@/assets/menu-icons/item-details.png"),
      to: "/store/product-sales-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Store Super Crease Report",
      image: require("@/assets/menu-icons/supercrease.png"),
      to: "/store/super-crease-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Corporate Report",
      image: require("@/assets/menu-icons/corporate.png"),
      to: "/store/corporate-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Customer Related Report",
      image: require("@/assets/menu-icons/customers.png"),
      to: "/store/customer-related-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Weekly Cash Report",
      image: require("@/assets/menu-icons/pricelist.png"),
      to: "/store/weekly-payment-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Customer Corporate Type",
      image: require("@/assets/menu-icons/upchargelist.png"),
      to: "/store/customer-corporate-type-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Customer Report",
      image: require("@/assets/menu-icons/schedule.png"),
      to: "/store/customer-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Referral Report",
      image: require("@/assets/menu-icons/settings-pos.png"),
      to: "/store/referral-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Sales Performance Report",
      image: require("@/assets/menu-icons/performance.png"),
      to: "/store/top-performance-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Productivity Report",
      image: require("@/assets/menu-icons/productivity.png"),
      to: "/store/productivity-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Refunded Items",
      image: require("@/assets/menu-icons/refund.png"),
      to: "/store/refund-report",
      description:
        "Here goes description there Here goes description thereHere goes description there",
    },
    {
      label: "Buy Backs",
      image: require("@/assets/menu-icons/buyback.png"),
      to: "/store/buyback-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Items Report",
      image: require("@/assets/menu-icons/performance.png"),
      to: "/store/items-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Sales Summary",
      image: require("@/assets/menu-icons/productivity.png"),
      to: "/store/sales-summary-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Transaction Report",
      image: require("@/assets/menu-icons/productivity.png"),
      to: "/store/transaction-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Stock Report",
      image: require("@/assets/menu-icons/productivity.png"),
      to: "/store/stock-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Service Summary Report",
      image: require("@/assets/menu-icons/productivity.png"),
      to: "/store/service-summary-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Daily Sales Report",
      image: require("@/assets/menu-icons/productivity.png"),
      to: "/store/daily-sales-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Cash Summary Report",
      image: require("@/assets/menu-icons/productivity.png"),
      to: "/store/cash-summary-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Tender Summary Report",
      image: require("@/assets/menu-icons/productivity.png"),
      to: "/store/tender-summary-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    }
  ];
}
</script>

<style scoped>
.ini-toolbar {
  padding: 9px 0px;
}
.menu-img-settings {
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 100%;
  margin: 10px;
}

.menu-link-settings {
  font-size: 18px;
  color: #004c97;
}

.menu-link-detail-settings {
  color: #68686b;
}
.section-menu {
  background-color: #fff;
  padding: 0px;
}
.hover-settings {
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
}
.hover-settings:hover {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  transition: 1s;
  background-color: #f7f7f7;
}
</style>