
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  components: {},
})

export default class ProductivityReport extends mixins(UtilityOptions) {
  private lists  = [];
  private reportService;
  private counter = 0;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Productivity Report" },
  ];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "None",
    filterBranch: 0,
    empFilter: 0,
    condition: '>',
    dimension: 'Associate',
    sort: 'DESC',
    limit: 10,
    amountValue: 0,
  };

  private dimensions = [
    {
      name: 'Associate'
    }
  ];

  private sortList = [
    {
      name: 'Sort by Ascending Order',
      value: 'ASC'
    },
    {
      name: 'Sort by Descending Order',
      value: 'DESC'
    }
  ];

  private conditions = [
    {
      name: '>',
      value:">"
    },
    {
      name: '<',
       value:"<"
    },
    {
      name: '<=',
      value:"<="
    },
    {
      name: '>=',
      value:">="
    },
    {
      name: '=',
      value:"="
    }
  ];

  private filterDates = [];
  private dialogTitle;
  private submitted = false;
  private filterBranch:any = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.submitted = false;
    this.dialogTitle = "Filter Productivity";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
      if(data.isAdmin == 'HQ')
      {
        this.filterBranch.push({
          'code': -1,
          'mall': 'All Stores'
        });

        this.searchAssociate.filterBranch = -1;
      }
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading         = true;
    this.reportService.productivityReport(this.searchAssociate).then((res) => {
      const data       = this.camelizeKeys(res);
      this.resultTitle = data.resultTitle;
      this.lists       = data.record;
      this.loading     = false;
    });
    this.productDialog   = false;
  }

  get employeeHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'code', header: 'Store Code'},
      {field: 'mall', header: 'Store Name'},
      {field: 'employeeId', header: 'Associate ID'},
      {field: 'employeeName', header: 'Associate Name'},
      {field: 'jobClass', header: 'Job Class'},
      {field: 'totalHrs', header: 'Total Approved Hours'},
    ];
  }

  get tableHeaders()
  {
    let headers:any = [];

    if(this.searchAssociate.dimension == 'Associate')
    {
      headers = this.employeeHeaders;
    }
   

    this.lists = [];

    return headers;
  }
}
